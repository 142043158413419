<template>

<b-container class="content">

  <div v-if="hasLaunched == false">
    <div class="feedback">
      <p>Welkom bij de 3D-weergave van het nieuwbouwproject! Klik op de knop hieronder om het model in AR te bekijken en op je tafel te projecteren.</p>
      <b-button @click="startAR" variant="primary" class="mt-3">Projecteer het model op je tafel</b-button>
      <div class="model-viewer-container">
    </div>
      <model-viewer 
              ref="modelViewer"
            :src="modelSrc" 
            :ios-src="iosSrc"
              ar 
              ar-modes="scene-viewer quick-look"
              shadow-intensity="1" 
              camera-controls 
              touch-action="pan-y"
              style="width: 300px; 
              height: 300px;"
              :ar-scale="arScale">              
              <!-- Maak de AR-knop volledig onzichtbaar -->
              <button slot="ar-button" style="display: none;"></button>              
    </model-viewer>
    </div>

  </div>
  <div v-else>
    
    <div v-if="feedbackGiven == false" >

      <div class="feedback">
        <h1>Leuk dat je de AR-maquette hebt bekeken!</h1> 
        <div v-if="rating == null">
          <p>Geef hieronder je feedback!</p> 
        </div>
      </div>

      <div v-if="rating == null" class="mt-4 d-flex justify-content-center">
        <b-button @click="giveFeedback(0)" class="rateme">😡</b-button>
        <b-button @click="giveFeedback(1)" class="rateme">☹️</b-button>
        <b-button @click="giveFeedback(2)" class="rateme">😐</b-button>
        <b-button @click="giveFeedback(3)" class="rateme">🙂</b-button>
        <b-button @click="giveFeedback(4)" class="rateme">😍 </b-button>
      </div>

      <b-button @click="restartAR" variant="primary" class="mt-3">Ik wil de AR nog een keer zien</b-button>
    </div>
    
    <div v-else class="mt-3">
      <p>{{ feedback }}</p>
    </div>
  </div>

</b-container>

    </template>

    <script>
    
    export default {
      props: ['shortCode'],
      name: 'ARViewer',
      data: function () {
        return{
          name: "ModelViewer",
          modelName:null,
          project: null,
          canActivate:false,
          isMobile:false,
          modelSrc:"",
          iosSrc: null,
          hasLaunched:false,
          feedback: "",
          sitelogId:"",
          feedbackGiven:false,
          clientId:null,
          rating: null
        }
      },
      components: {
      },
      created:function(){

          this.getUrl();

      },
      mounted:function(){
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        window.addEventListener('touchmove', this.preventScroll, { passive: false });
        this.$emit('setHeaderFooter', false);

      },
      methods: {
         async getUrl() {
            const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`)
            .then(response => response.json())
            .then(data => {

              this.modelSrc = data.url;
              this.iosSrc = data.iosUrl;
             // console.log(data);
             var name = this.$utils.extractFileName(data.url);
             this.modelName = name;
             this.project = data.project;

             this.getSiteLog();
            })
            .catch(error => {
              console.error('Fout bij het ophalen van gegevens:', error);
            });
          },    
          preventScroll(event) {
              event.preventDefault(); // Voorkom de standaard scroll actie
          },
          restartAR(){
              this.hasLaunched = false;
          },
          async getSiteLog(){
            var sitelogResult  = await this.$utils.getsiteLog(this.modelName);
            this.hasLaunched = sitelogResult != null && sitelogResult.rating == null;
            this.sitelogId = sitelogResult.rowKey;
            this.rating = sitelogResult.rating;

          },
          async startAR() {
            
            var sitelogResult  = await this.$utils.siteLog("ARViewer", this.modelName, this.project);
            this.sitelogId = sitelogResult.id;

            if(this.$refs.modelViewer.canActivateAR){
              this.$refs.modelViewer.activateAR();

              setTimeout(() => {
                  this.hasLaunched = true;
              }, 2000);
            }
        },
    async giveFeedback(rating) {
      this.feedback = "Bedankt voor je feedback!";
      this.feedbackGiven = true;
      var result = await this.$utils.updateRating(this.sitelogId, rating);
    }
      },
      computed: {
        arScale() {
        return this.modelSrc.includes("_maquette") ? "auto" : "fixed";
      }
     },
     beforeDestroy() {
        // Verwijder de event listener bij het vernietigen van de component
        window.removeEventListener('touchmove', this.preventScroll);
      }
    }
    
    </script>
    
<style scoped>
html, body {
  overflow: hidden;
  height: 100%;
  margin: 0;
}

h1{
  font-size: 24px;
}

.feedback{
  text-align: left;
  padding: 20px;
}

.content{
  padding-top:20px;
}

.model-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rateme{
  width: 60px;
  height: 60px; 
  padding: 0; 
  background-color: transparent; 
  border: none; 
  font-size: 40px;
}
</style>