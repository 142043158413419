<template>
    <div id="app">

        <b-container class="content">

                <p> <input v-model="url" class="url" :style="{ width: qrsize + 'px' }"> </p>

                <div class="divje" :style="{ width: qrsize + 'px' }">
                    <qr-code  :text="url" :size="qrsize" ref="qrCode"></qr-code>
                </div>

        </b-container>
    </div>  
  </template>
  
  <script>

  import VueQRCodeComponent from 'vue-qrcode-component'
  
  export default {
    name: 'QR',
    components: {
        'qr-code': VueQRCodeComponent
    },
    data() {
      return {
        url: 'https://dup.rnd.amsterdam.nl/',
        qrsize:600
      };
    },
    created: function(){
      if(this.$route.query.url != undefined){
        const encodedUrl = this.$route.query.url;
        const decodedUrl = decodeURIComponent(encodedUrl);
        this.url=decodedUrl;  
      }
    },
    mounted: function(){
    },
    methods: {
    }
    
  };
  </script>
  
  <style>
    .url{
            border-radius: 4px;
            border-color: #eee;
            border-width: 2px;
            border-style:double;
    }

    .divje{
        margin: 0 auto;
    }

  </style>
  