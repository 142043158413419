<template>
  
  <div>

    <a-scene ref="scene" xr-mode-ui="enabled: false" shadow="type: pcfsoft"  @loaded="onSceneLoaded">
      <a-camera raycaster="objects: .clickable"  ref="camera" id="camera" :position="cameraPosition" look-controls="enabled: true; touchEnabled: false" camera="near: 0.1; far: 1000">
      </a-camera>

      <a-entity cursor="rayOrigin: mouse"></a-entity>

      <a-light ref="light" type="directional" position="10 20 10" intensity="1" castShadow="true"></a-light>
      <a-light type="ambient" intensity="0.5" color="#ffffff"></a-light>

      <a-plane ref="plane" radial-floor position="0 0 0" rotation="-90 0 0" width="300" height="300" color="#6B8E23" receiveShadow="true"></a-plane>

      <a-sky ref="sky" src="../../images/overcast_soil_puresky_1k.png"></a-sky>

      <a-entity ref="model" :gltf-model="modelUrl" scale="1 1 1" position="0 -1 -20" castShadow="true" material="transparent: true;"></a-entity>

      <a-entity class="clickable" look-at="[camera]" position="0 2 -12" geometry="primitive: box; height: 5; width: 5;" material="src: url(images/waypoint.png); transparent: true;shader: flat;" ></a-entity>
      <a-entity class="clickable" look-at="[camera]" position="-30 2 -8" geometry="primitive: box; height: 5; width: 5;" material="src: url(images/waypoint.png);transparent: true;shader: flat;"  ></a-entity>
      <a-entity class="clickable" look-at="[camera]" position="30 2 -8" geometry="primitive: box; height: 5; width: 5;" material="src: url(images/waypoint.png);transparent: true;shader: flat;"  ></a-entity>
      <a-entity class="clickable" look-at="[camera]" position="30 11 -17" geometry="primitive: box; height: 5; width: 5;" material="src: url(images/waypoint.png);transparent: true;shader: flat;" ></a-entity>

    </a-scene>

  </div>
</template>

<script>

import 'aframe';
import 'aframe-look-at-component';
import { generateRadialFloorTexture } from '../generateRadialFloorTexture.js';


AFRAME.registerComponent('radial-floor', {
    init: function () {
      const texture = generateRadialFloorTexture(512);
      const material = new THREE.MeshStandardMaterial({ 
        map: texture,
        transparent: true,
        color: 0x6B8E23,
        roughness: 1,
        metalness: 0.0,
        side: THREE.DoubleSide,
      });
      const plane = this.el.getObject3D('mesh');
      plane.material = material;
    }
  });

export default {
  props: ['shortCode'],
  name: 'App',
  data() {
    return {
      project: null,
      sitelogId:"",
      name: 'Sensors',
      modelUrl: '',
      buttonStyle: {
        top: '0px',
        left: '0px',
      },
      cameraPosition: new THREE.Vector3(0, 1.6, 0),
      cameraPosvar: new THREE.Vector3(0, 1.6, 0),
      animationFrameId: null,
      animationFrameId2: null,
      camera:null,
      pointerPos:new THREE.Vector3(),
      counter:0,
      pointers: [],
      initialDistance:0,
      currentPointer: null
    };
  },
  methods: {
    async getUrl() {
        const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`)
        .then(response => response.json())
        .then(data => {
          this.modelUrl = data.url;
          this.project = data.project;
        })
        .catch(error => {
          console.error('Fout bij het ophalen van gegevens:', error);
        });
      },    
    onClick(event) {
        this.onPointerClick(event.target);
    },
    onSceneLoaded() {

      var modelEntity = this.$refs.model;
      modelEntity.addEventListener('model-loaded', () => {
        modelEntity.object3D.traverse((child) => {
          if (child.isMesh) {
            child.raycast = () => {};
          }
        });
      });

      this.pointers = document.querySelectorAll('.clickable');

      this.pointers.forEach((element) => {
        element.addEventListener('click', this.onClick);
      });

      this.camera = this.$refs.camera.getObject3D('camera');

    },
    onPointerClick(ev){

      this.currentPointer = ev;
      
      this.pointers.forEach((element) => {
        element.setAttribute('material', 'opacity', 1);
        element.object3D.visible = true;
      });

      ev.object3D.getWorldPosition(this.pointerPos);
      this.initialDistance = this.cameraPosition.distanceTo(this.pointerPos);


    //  ev.removeEventListener('click', this.onClick);

      this.animate();
    },
    animate() {
      this.cameraPosvar.lerp(this.pointerPos, 0.1);
      this.cameraPosition = new THREE.Vector3(this.cameraPosvar.x, this.cameraPosvar.y, this.cameraPosvar.z)
      
      const distance = this.cameraPosition.distanceTo(this.pointerPos);

      const progress = 1 - (distance / this.initialDistance);

      const opacity = Math.max(0, 1 - progress);  // Zorg ervoor dat de opacity niet negatief wordt
      this.currentPointer.setAttribute('material', 'opacity', opacity);
  
  if (distance < 0.01) {
 //   this.currentPointer.object3D.visible = false;
    cancelAnimationFrame(this.animationFrameId2);
    return;
  }
      
      this.animationFrameId2 = requestAnimationFrame(this.animate);
    },

  },
  created:function(){
  },
  mounted: async function(){
    await this.getUrl();
    var modelName = this.$utils.extractFileName(this.modelUrl);
    var sitelogId = await this.$utils.siteLog("3DViewer", modelName, this.project);

  },
  beforeDestroy() {
    cancelAnimationFrame(this.animationFrameId);
    cancelAnimationFrame(this.animationFrameId2);
  }
};
</script>

<style scoped>
.ontop {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  color: white;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.html-button{
  position: absolute;
  z-index: 10;
  color: white;
  color:#000;
  border-radius: 40px;
  width: 80px;
  height: 80px;
}

</style>
