<template>

      <div ref="threeContainer" class="three-container">
        <div class="overlay">
          <b-button @click="centerModel">Center Model</b-button>
          <b-button @click="downloadImage">Download Image</b-button>
          <!-- <b-button @click="downloadTextures">Download textures</b-button> -->

          <label style="color: black; background: white; padding: 5px; border-radius: 5px; z-index: 100;">
            <input type="checkbox" v-model="usePathTracing" @change="toggleRendering" />
            Realistic
          </label>

          <label v-if="usePathTracing" style="color: black; background: white; padding: 5px; border-radius: 5px; z-index: 100;">
            <input type="checkbox" v-model="is360" @change="toggle360" />
            360
          </label>

        </div>

        <div 
      v-if="usePathTracing" 
      style="position: absolute; bottom: 10px; left: 10px; color: black; background: white; padding: 5px; border-radius: 5px; z-index: 100;"
    >
      Samples: {{ sampleCount }}
    </div>

      </div>

</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EquirectCamera, WebGLPathTracer} from "three-gpu-pathtracer";
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { ParallelMeshBVHWorker } from 'three-mesh-bvh/src/workers/ParallelMeshBVHWorker.js';
import { generateRadialFloorTexture } from '../generateRadialFloorTexture.js';


export default {
  props: ['shortCode'],

  mounted() {

    this.initThreeJS();
    this.createUI();
    this.getUrl();
  },
  data: function () {
      return {
        gui:null,
        planeSize:500,
        upscaleThreshold: 10,
        scene:null,
        currentcamera:null,
        camera:null,
        camera360:null,
        controls:null,
        sunlight: null,
        ambientLight: null,
        animationFrameId: null,
        renderer:null,
        usePathTracing: false,
        is360: false,
        sampleCount: 0,
        params:{
          ambientColor: '#ffffff',
          ambientIntensity: 0.058,
          lightColor: '#ffffff',
          lightIntensity: 3,
          angle:114,
          lightHeight: 3.52,
          environmentIntensity: 0.4,
          environmentRotation:4.4,
          filterGlossyFactor: 0.5,
          envMap:"",
          envMaps : {
            'Pure Sky': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/aristea_wreck_puresky_2k.hdr',
            'Moonless Golf': 'https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/textures/equirectangular/moonless_golf_1k.hdr',
            'Noon Grass': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/noon_grass_2k.hdr',
          'Sepulchral Chapel Rotunda': 'https://raw.githubusercontent.com/gkjohnson/3d-demo-data/master/hdri/sepulchral_chapel_rotunda_2k.hdr'
        }
        },

      };
    },
  methods: {
    initThreeJS() {
      this.scene = new THREE.Scene();

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,  // Zorg ervoor dat de canvas behouden blijft na renderen,
      });
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.shadowMap.enabled = true; // Enable shadows
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Zachtere schaduwen

      this.$refs.threeContainer.appendChild(this.renderer.domElement);

      this.loadEnvMap();

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      this.camera.position.set(0, 100, -300);
      this.camera.lookAt(0, 0, 0);

      this.camera360 = new EquirectCamera();

      this.currentcamera = this.camera;

      this.addSunlight();

      this.updateLightParams();

      this.pathTracer = new WebGLPathTracer(this.renderer);
      this.pathTracer.setBVHWorker( new ParallelMeshBVHWorker());
      this.pathTracer.physicallyCorrectLights = true;
      this.pathTracer.tiles.set( 3, 3 );
      this.pathTracer.multipleImportanceSampling = true;
      this.pathTracer.transmissiveBounces = 10;
      this.pathTracer.minSamples = 0;


      this.pathTracer.setScene(this.scene, this.currentcamera);  


      this.addFloor();

      this.addControls();

      this.animate();

      window.addEventListener( 'resize', this.onResize );

    },
    onParamsChange() {

      this.pathTracer.filterGlossyFactor = this.params.filterGlossyFactor;

      this.scene.environmentIntensity = this.params.environmentIntensity;
      this.scene.environmentRotation.y = this.params.environmentRotation;

      this.scene.background = this.scene.environment;
      this.scene.backgroundIntensity = this.params.environmentIntensity;
      this.scene.backgroundRotation.y = this.params.environmentRotation;

      this.pathTracer.updateMaterials();
      this.pathTracer.updateEnvironment();

    },
    onResize() {
      const w = window.innerWidth;
      const h = window.innerHeight;
      const dpr = window.devicePixelRatio;

      this.renderer.setSize( w, h );
      this.renderer.setPixelRatio( dpr );

      const aspect = w / h;
      this.camera.aspect = aspect;
      this.camera.updateProjectionMatrix();
      this.pathTracer.updateCamera();
    },
    addSunlight(){
      this.sunlight = new THREE.DirectionalLight(this.params.lightColor, this.params.lightIntensity);
    //  this.sunlight.position.set(10, this.params.lightHeight, 10); // Verhoog de hoogte aanzienlijk om een groot gebied te verlichten
      this.sunlight.castShadow = true;
      this.sunlight.shadow.mapSize.width = 4096; // Verhoog de schaduwkwaliteit
      this.sunlight.shadow.mapSize.height = 4096;
      this.sunlight.shadow.bias = -0.0005;  
      this.sunlight.shadow.camera.left = -1000;    // Halve breedte van het gebied
      this.sunlight.shadow.camera.right = 1000;
      this.sunlight.shadow.camera.top = 1000;
      this.sunlight.shadow.camera.bottom = -1000;
      this.sunlight.shadow.camera.near = 1;       // Beginpunt van het schaduwvolume (dichtbij)
      this.sunlight.shadow.camera.far = 10000;     // Verre afstand voor het schaduwvolume
      this.scene.add(this.sunlight);
    },
    addFloor(){

      const floorTex = generateRadialFloorTexture( 2048 );

      var floorPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(),
        new THREE.MeshStandardMaterial( {
          map:floorTex,
          transparent: true,
          color: 0xffffff,
          roughness: 1,
          metalness: 0.0,
          side: THREE.DoubleSide,
        } )
      );
      floorPlane.name = "floor";
      floorPlane.scale.setScalar( 500 );
      floorPlane.rotation.x = - Math.PI / 2;
      floorPlane.receiveShadow = true; 
      this.scene.add( floorPlane );
    },
    addControls(){
      this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls.addEventListener( 'change', () => {
        this.pathTracer.updateCamera();
      } );
      this.controls.object = this.camera;
      this.controls.update();
      this.pathTracer.setCamera( this.camera );
    },
    centerModel(){
        this.$utils.centerScene(this.scene, true, true);
    },
    async getUrl() {
        const response = await fetch(`${this.$store.apiUrl}/geturl/${this.shortCode}`)
        .then(response => response.json())
        .then(data => {
          this.loadModel(data.url);
         //this.addCube(0,100,0);
        })
        .catch(error => {
          console.error('Fout bij het ophalen van het glb model:', error);
        });
    },
    addCube(x,y,z){

      //const geometry = new THREE.BoxGeometry(100, 100, 100); 
      const geometry = new THREE.SphereGeometry( 100, 32, 16 );
      const material = new THREE.MeshStandardMaterial({ 
        color: 0xffffff,
        roughness :0,
        metalness : 1,
      });

      const cube = new THREE.Mesh(geometry, material);
      cube.castShadow = true;
      
      const pos = new THREE.Vector3(x,y,z);
      cube.position.copy(pos);

      this.scene.add(cube);
    },
    downloadTextures(){

      var counter = 0;

      this.scene.traverse((child) => {
        counter++;

        if (child.isMesh && child.material.map) {
          const texture = child.material.map;
          const image = texture.image;

          // Maak een canvas element om de image te renderen
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext('2d');

          // Render de afbeelding in de canvas
          context.drawImage(image, 0, 0);

          // Zet de canvas om naar een data URL (afbeelding)
          const dataURL = canvas.toDataURL('image/png');

          // Maak een download link voor de afbeelding
          const link = document.createElement('a');
          link.href = dataURL;
          link.download = `texture${counter}.png`; // De naam van de gedownloade afbeelding
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

      });
    },
    downloadImage(){
      const canvas = this.renderer.domElement;
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'render.png';
      link.click();
    },
    loadModel(url_glb){
            const loader = new GLTFLoader();

            loader.load(
                url_glb,
                (gltf) => {
                    var model = gltf.scene;
                    model.name = "glb model";
                    model.traverse((object) => {
                        if (object.isMesh && object.name !== 'floor' && object.name !== "Cubes") {
                          object.material.roughness = 0.3;
                          object.material.metalness = 0.4;
                          object.castShadow = true;
                          object.receiveShadow = true;
                         // console.log(`Materialen van ${object.name}:`, object.material);
                        }
                    });
                    this.scene.add(model);
                  });
    },
    debugScene(scene) {
      const totalBoundingBox = new THREE.Box3();

      scene.traverse((child) => {
        if (child.isMesh && child.name !== "floor" && child.name !== "Cubes") {
          child.geometry.computeBoundingBox();
          const boundingBox = child.geometry.boundingBox.clone().applyMatrix4(child.matrixWorld);
          totalBoundingBox.union(boundingBox);
        }
      });

      const size = new THREE.Vector3();
      totalBoundingBox.getSize(size);

      const width = size.x;
      const height = size.y;
      const depth = size.z;

      // Bereken het midden van de totale bounding box
      const center = totalBoundingBox.getCenter(new THREE.Vector3());
      console.log(`Total Dimensions: Width: ${width}m, Height: ${height}m, Depth: ${depth}m`);
      console.log(`Center of Bounding Box: (${center.x}, ${center.y}, ${center.z})`);
    }
    ,
    calculateUVs(geometry) {
      const position = geometry.attributes.position.array;
      const uvs = [];

      for (let i = 0; i < position.length; i += 3) {
          // Eenvoudige UV-projectie (bijvoorbeeld orthografisch)
          const x = position[i];
          const z = position[i + 2]; // Gebruik Z voor 2D projectie

          // Normeer de waarden naar een [0, 1] bereik
          uvs.push((x + 1) / 2); // X naar [0, 1]
          uvs.push((z + 1) / 2); // Z naar [0, 1]
      }
      return uvs;
    },
    toggleRendering() {
      if (this.usePathTracing) {
        this.pathTracer.setScene(this.scene, this.camera);
      }
      this.controls.enabled = true;
      this.is360 = false;
    },
    toggle360() {
      if (this.is360) {
        this.controls.enabled = false;
        this.camera360.position.copy(this.camera.position);
        this.currentcamera = this.camera360;
      }
      else{
        this.controls.enabled = true;
        this.currentcamera = this.camera;
      }
      this.pathTracer.setScene(this.scene, this.currentcamera);
      this.pathTracer.updateCamera();


    },
    downloadRender() {
      const canvas = this.renderer.domElement;
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'render.png';
      link.click();
    },
    animate() {
      this.animationFrameId = requestAnimationFrame(this.animate);
      if (this.usePathTracing) {
        this.pathTracer.renderSample();
        this.sampleCount = parseInt(this.pathTracer.samples, 10);
      } else {
        this.renderer.render(this.scene, this.camera);
      }
      this.controls.update();
    },
    createUI(){
      this.gui = new GUI();
      this.gui.domElement.style.marginTop = '62px';
      const env = this.gui.addFolder('Environment');
      env.add( this.params, 'envMap', this.params.envMaps ).name( 'map' ).onChange( this.loadEnvMap) ;
      env.add(this.params, 'environmentIntensity', 0, 1).onChange(this.onParamsChange);
      env.add(this.params, 'environmentRotation', 0, 2 * Math.PI).onChange(this.onParamsChange);

      const lightFolder = this.gui.addFolder('Sun Light');
      lightFolder.add(this.params, 'lightIntensity', 0, 5).onChange(this.updateLightParams);
      lightFolder.addColor(this.params, 'lightColor').onChange(this.updateLightParams);
      lightFolder.add(this.params, 'angle', 0, 360).name('Light Rotation').onChange(this.updateLightParams);
      lightFolder.add(this.params, 'lightHeight', 0, 10).name('Light Height').onChange(this.updateLightParams); // Slider voor hoogte
      lightFolder.open();

    },
    loadEnvMap(url) {

        if(url == null){
          this.params.envMap = Object.keys(this.params.envMaps)[0];
          url = this.params.envMaps[this.params.envMap];
        }
  
        new RGBELoader()
          .load( url, texture => {

            if ( this.scene.environment ) {
              this.scene.environment.dispose();
            }

            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.environment = texture;
            this.pathTracer.updateEnvironment();
            this.onParamsChange();
          } );
        },

    updateLightParams(){
      this.sunlight.color.set(this.params.lightColor);
      this.sunlight.intensity = this.params.lightIntensity;
      this.sunlight.position.y = this.params.lightHeight; 

      const angleInRadians = (this.params.angle * Math.PI) / 180;
        this.sunlight.position.set(
          Math.cos(angleInRadians) * 5,
          this.sunlight.position.y,
          Math.sin(angleInRadians) * 5
        );

        // this.scene.backgroundIntensity = this.params.environmentIntensity;
        // this.scene.backgroundRotation.y = this.params.environmentRotation;

      // this.ambientLight.color.set(this.params.ambientColor);
      // this.ambientLight.intensity = this.params.ambientIntensity;
      
      if(this.usePathTracing){
        this.pathTracer.updateLights();
      }
    }
    
  },
  beforeDestroy() {
    if (this.gui) {
      this.gui.destroy();
    }
}
};
</script>

<style scoped>
.three-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin-top: 0px;
}


.btn{
  margin-left: 2px;
}

</style>
